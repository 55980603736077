<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <v-container>

          <v-row justify="center" class="mt-4 px-4">

            <v-col class="text-center" cols="12" md="4">
              <v-img max-height="100"
                     class="my-1"
                     contain
                     src="https://app-biocredit.s3.amazonaws.com/web_app/biocredit/images/check.png"
              ></v-img>
            </v-col>

            <v-col cols="12" class="mt-0 pt-0">
              <div class="mt-0 pt-0">
                <h1 class="text-center today-regular">¡FELICITACIONES!</h1>
              </div>
            </v-col>

            <v-col cols="12" md="6" >
              <p class="text-center mb-0 today-regular line-height-1">Tú solicitud de financiación para <b>IMUSA</b> ha sido:</p>
            </v-col>

            <v-col cols="12" class="">
              <div class="mt-0 pt-0">
                <h1 class="primary--text text-center today-regular" style="font-size: 2.5em;">¡APROBADO!</h1>
              </div>
            </v-col>

          </v-row>



          <v-row justify="center" class="px-4">
            <v-col cols="12" md="6" >

              <v-card color="grey lighten-5" elevation="0">

                <v-row justify="center" class="px-4">
                  <v-col cols="2" md="2">
                    <v-img max-height="30"
                           class="my-4"
                           contain
                           src="https://app-biocredit.s3.amazonaws.com/web_app/coopcentral/correo-electronico.png"
                    ></v-img>
                  </v-col>
                  <v-col justify-center cols="10" md="10" class="my-auto">
                    <p class="black--text mb-0 today-regular" style=" line-height: 1;">Te hemos enviado la documentación al correo: <b>cesaraldana@gmail.com</b></p>
                  </v-col>
                </v-row>

              </v-card>

            </v-col>
          </v-row>

          <v-layout justify-center class="mt-8">
            <v-card-actions>
              <v-btn
                  :loading="loading"
                  :disabled="loading"
                  @click="goHome"
                  color="primary"
                  class="px-12 text-capitalize vardi-button"
                  elevation="0">
                Finalizar
              </v-btn>
            </v-card-actions>
          </v-layout>

        </v-container>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";
import CurrencyInput from "@/components/CurrencyInput";

export default {
  name: 'FinishRequest',
  components: {LeftSideImage, NavBarPage, CurrencyInput},
  data() {
    return {
      dialog: false,
      loading: false,
      settings: null,
      request: null,
      rules: {
        min: value => parseInt(value.replaceAll(',', '').replace('$', '')) > this.minAmount || 'Este valor debe ser mayor que el pago mínimo' + ' - ' + parseInt(value.replaceAll(',', '').replace('$', '')),
        max: value => parseInt(value.replaceAll(',', '').replace('$', '')) <= this.valueFinance || 'Este valor no debe ser mayor que el valor de la poliza',
      },
    }
  },
  mounted() {
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },

  },
  created: function() {

    this.settings = locals.getLocal('settings');
    this.request = locals.getLocal('request');

  }
}
</script>

<style lang="scss">
.v-stepper__label {
  font-size: 13px;
}

.active-step .v-stepper__label {
  color: #0E4A7D !important;
}

.active-step > span  {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #0E4A7D !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #0E4A7D !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 400px;
  overflow: scroll;
  overflow-x: hidden;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}

</style>
